<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Detail ShippingPrice</v-card-title>
    <v-row no-gutters>
        <div class="form-detail">
           <v-row>
            <v-col cols="4" md="5">
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">customer_rank_id:</v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{item.customer_rank_id? item.customer_rank_id : ''}}</v-card-text>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">service_id:</v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{item.service_id? item.service_id : ''}}</v-card-text>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">min_weight:</v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{item.min_weight? item.min_weight : ''}}</v-card-text>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="0" md="2"></v-col>
            <v-col cols="12" md="5">
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">price:</v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{item.price? item.price : ''}}</v-card-text>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">currency:</v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{item.currency? item.currency : ''}}</v-card-text>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">zone:</v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{item.zone? item.zone : ''}}</v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="onClose">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'DetailShippingPrice',
  props: ['selectedItem', 'onClose'],
  data: () => ({
    item: {}
  }),
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) this.item = this.selectedItem;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style scoped>
.form-detail {
  display: block;
  width: 100%;
  border: 1px solid #ddd;
}

.title-detail {
  padding: 15px 15px 0 15px;
  margin-bottom: 0px;
}

.form-detail >>> .v-subheader {
  height: 32px;
}
</style>
